






















































































































































































































































































































































interface IPractice {
  id: string;
  name: string;
}

interface IColumns {
  label: string;
  field: string;
}

interface ISortBy {
  field: string;
  type: SortType;
}
enum SortType {
  asc,
  desc,
}

class Filters {
  OrderBy: ISortBy;
  Take: number;
  Skip: number;
  SearchTerm?: string;
}

interface ListResponse {
  rowsCount: number;
  list: any;
}

interface UpdateResponse {
  updatedEntity: UserPractice;
  success: boolean;
  message: string;
}
import _ from "lodash";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import axios from "axios";
import { DatePicker } from "ant-design-vue";
import {
  patiientMapping,
  practiceMapping,
  Types,
  ColumnStatus,
} from "../views/Models/TableColumnsMapping";
import {
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BForm,
  BSidebar,
  VBToggle,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from "bootstrap-vue";
//import { VueGoodTable } from 'vue-good-table'
//const VueGoodTable = require("vue-good-table");
import store from "@/store/index";
import { Subscription, Subscriber, Subject, fromEvent, Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";
import {
  tabelSettings,
  ITableSettings,
  userPracticeTableSettings,
} from "../views/Models/TableSettings";
import { UserPractice } from "../views/Models/TableEditClass";
import { PropType } from "vue";

import vSelect from "vue-select";
//import {VCol,VRow,VForm,VContainer,VTextField,VDatePicker,VMenu,VBtn,VSpacer,VApp,VDialog} from 'vuetify/lib'
import { data } from "jquery";

//import '../libs/axios.d.ts'
@Component({
  name: "data-table",
  components: {
    DatePicker,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BCard,
    BAvatar,
    BSidebar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BButton,

    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
  },
})
export default class extends Vue {
  @Prop({
    default: {
      TableLabel: "",
      TableName: "",
      EndPoint: "",
      TableMapping: patiientMapping,
      TableParams: null,
    },
    type: Object as PropType<ITableSettings>,
  })
  SelectedTable!: ITableSettings;

  @Prop({ default: false })
  DisableEdit!: boolean;
  @Prop({ default: false })
  DisableNew!: boolean;
  @Prop({ default: false })
  DisableDelete!: boolean;
  types: any = Types;
  SidebarStatus: any = ColumnStatus;

  columns: IColumns[] = this.SelectedTable.TableMapping;
  UserPracticeSettings: UserPractice = new UserPractice({
    isDefault: false,
    eligibilityEmails: false,
    doctorEmails: false,
    doctorEmailsReply: false,
  });
  rows: any = [];
  dir: boolean = false;
  searchTerm: string = "";
  rowsCount: number = 0;
  filters: Filters = {
    Take: 50,
    OrderBy: {
      field: "Id",
      type: SortType.asc,
    },
    Skip: 0,
    SearchTerm: "",
  };
  btnLoading: boolean = false;
  subject = new Subject<string>();
  private searchEventSubscription: Subscription;

  listsForDropdown: any = {};

  direction() {
    if (store.state.appConfig.isRTL) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = true;
      return this.dir;
    }
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    this.dir = false;
    return this.dir;
  }
  created() {
    this.getList();
    
    
    this.subject.asObservable();

    this.searchEventSubscription = this.subject
      .pipe(debounceTime(2000))
      .subscribe((value) => {
        this.filters.SearchTerm = value;
        this.getList();
      });
  }
  NewStarted() {
    console.log(JSON.stringify(this.SelectedTable))
    this.SelectedTable.TableMapping.forEach((element) => {

      if (element.dropdownEndpoint) {
        this.ListsForDprpdown(element.dropdownEndpoint);
      }
    });
    this.UserPracticeSettings = new UserPractice();
  }
  handleEdit(row: UserPractice) {
    console.log(`The user pressed edit for: ${JSON.stringify(row)}`);
    this.UserPracticeSettings = row;
  }
  handleSearch(searching: string) {
    this.subject.next(searching);
    console.log(`The user searched for: ${searching}`);
  }
  handleChangePage(page: number) {
    this.filters.Take = page;
    this.getList();
    console.log(`The user changed the page to: ${page}`);
  }
  handlePageChange(active: any) {
    this.filters.Skip = active * this.filters.Take - this.filters.Take;
    this.getList();
    console.log(`the user change page:  ${active}`);
  }
  onSortChange(params: ISortBy[]) {
    this.filters.OrderBy = params[0];
    this.getList();
    console.log(`the user ordered:  ${JSON.stringify(this.filters.OrderBy)}`);
  }
  getList() {
    axios
      .post(`/${this.SelectedTable.EndPoint}/read`, this.filters, {
        withCredentials: true,
      })
      .then((response) => {
        var listResponse: ListResponse = response.data;

        this.rows = response.data.list;
        this.rowsCount = listResponse.rowsCount;
        window.console.log(this.rowsCount);
      });
  }
  SaveChanges(row: UserPractice) {
    this.btnLoading = true;
    this.UserPracticeSettings.isDefault =
      this.UserPracticeSettings.isDefault != null
        ? this.UserPracticeSettings.isDefault
        : false;
    console.log(JSON.stringify(this.UserPracticeSettings));
    axios
      .post(
        `/${this.SelectedTable.EndPoint}/create`,
        this.UserPracticeSettings,
        { withCredentials: true }
      )
      .then((response) => {
        var updatedResponse: UpdateResponse = response.data;
        if (updatedResponse.success) {
          row = response.data;
          Vue.$toast("Saved Succesfuly", {
            timeout: 2000,
          });
        } else {
          Vue.$toast.error("Error While Updating", {
            timeout: 2000,
          });

          Vue.$toast.error(`Error Massege ${updatedResponse.message}`, {
            timeout: 2000,
          });
        }

        window.console.log(response.data);
      })
      .finally(() => {
        this.btnLoading = false;
      });
  }
  UpdateChanges(row: UserPractice) {
    this.btnLoading = true;
    console.log(JSON.stringify(this.UserPracticeSettings));
    axios
      .post(
        `/${this.SelectedTable.EndPoint}/update`,
        this.UserPracticeSettings,
        { withCredentials: true }
      )
      .then((response) => {
        var updatedResponse: UpdateResponse = response.data;
        if (updatedResponse.success) {
          row = response.data;
          Vue.$toast("Updated Succesfuly", {
            timeout: 2000,
          });
        } else {
          Vue.$toast.error("Error While Updating", {
            timeout: 2000,
          });

          Vue.$toast.error(`Error Massege ${updatedResponse.message}`, {
            timeout: 2000,
          });
        }

        window.console.log(response.data);
      })
      .finally(() => {
        this.btnLoading = false;
      });
  }

  DeleteRecord(key: number) {
    console.log(JSON.stringify(key));
    axios
      .get(`/${this.SelectedTable.EndPoint}/delete?key=${key}`, {
        withCredentials: true,
      })
      .then((response) => {
        var updatedResponse: UpdateResponse = response.data;
        if (updatedResponse.success) {
          Vue.$toast("Updated Succesfuly", {
            timeout: 2000,
          });
        } else {
          Vue.$toast.error("Error While Updating", {
            timeout: 2000,
          });

          Vue.$toast.error(`Error Massege ${updatedResponse.message}`, {
            timeout: 2000,
          });
        }

        window.console.log(response.data);
      });
  }

  isDate(value: any) {
    return !isNaN(Date.parse(value));
  }
  ListsForDprpdown(endpoint: string) {
    var filters = {
      OrderBy: {
        field: "Id",
        type: SortType.asc,
      },
      SearchTerm: "",
    };
    axios
      .post(`/${endpoint}/read`, filters, {
        withCredentials: true,
      })
      .then((response) => {
        var listResponse: ListResponse = response.data;

        this.listsForDropdown = Object.assign(this.listsForDropdown, {
          [endpoint]: listResponse.list,
        });
        this.$forceUpdate();
        window.console.log(this.rowsCount);
      });
  }
  beforeDestroy() {
    this.searchEventSubscription.unsubscribe();
  }
}
