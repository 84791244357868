import { values } from "lodash";

interface IColumns {
  label: string;
  field: string;
  mainStatus?:ColumnStatus;
  SidebarNewStatus: ColumnStatus,
  SidebarEditStatus: ColumnStatus,
  Type: Types
  dropdownEndpoint?: string,
  DropdownKey?: string,
  DropdownValue?: string,
  DropdownMapId?: string,
  EnumValues?: KeyValuePair[]
}

class KeyValuePair {
  key: number;
  value: string;
  public constructor(init?: Partial<KeyValuePair>) {
    Object.assign(this, init);
  }
}
enum Types {
  string,
  number,
  boolean,
  dropdown,
  dateTime,
  enum,
  any
}
enum ColumnStatus {
  Inabeld,
  Disabeld,
  Hidden
}
enum Roles {
  User = 1,
  Admin = 2
}

const patiientMapping: IColumns[] = [
  {
    label: 'Name',
    field: 'name',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.dropdown
  },
  {
    label: 'Practice',
    field: 'practiceName',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.dropdown
  },
  {
    label: 'Chart',
    field: 'chart',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.dropdown
  },
  {
    label: 'Date Of Birth',
    field: 'dob',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.dropdown
  },
  {
    label: 'Address',
    field: 'address1',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.dropdown
  },
  {
    label: 'City',
    field: 'city',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.dropdown
  },
  {
    label: 'State',
    field: 'state',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.dropdown
  },
  {
    label: 'Zip',
    field: 'zip',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.dropdown
  },
  {
    label: 'Home Phone',
    field: 'homePhone',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.dropdown
  },
  {
    label: 'Cell Phone',
    field: 'cellPhone',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.dropdown
  }
  //  {
  //     label: 'Action',
  //     field: 'action',
  //   }
]

const userPracticeMapping: IColumns[] = [
  {
    label: 'Practice',
    field: 'practiceName',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.dropdown,
    dropdownEndpoint: 'Practice',
    DropdownKey: 'id',
    DropdownValue: 'name',
    DropdownMapId: 'practiceId'
  },
  {
    label: 'User',
    field: 'userName',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.dropdown,
    dropdownEndpoint: 'User',
    DropdownKey: 'id',
    DropdownValue: 'name',
    DropdownMapId: 'userId'
  },
  {
    label: 'Is Default Email Sender',
    field: 'isDefault',
    SidebarEditStatus: ColumnStatus.Inabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.boolean
  },
  {
    label: 'Doctor Emails Reply',
    field: 'doctorEmailsReply',
    SidebarEditStatus: ColumnStatus.Inabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.boolean
  },
  {
    label: 'Doctor Emails CC',
    field: 'doctorEmails',
    SidebarEditStatus: ColumnStatus.Inabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.boolean
  },
  {
    label: 'Eligibility Emails',
    field: 'eligibilityEmails',
    SidebarEditStatus: ColumnStatus.Inabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.boolean
  },
  {
    label: 'Date Created',
    field: 'dateCreated',
    SidebarEditStatus: ColumnStatus.Hidden,
    SidebarNewStatus: ColumnStatus.Hidden,
    Type: Types.dateTime
  },
  {
    label: 'Action',
    field: 'action',
    SidebarEditStatus: ColumnStatus.Hidden,
    SidebarNewStatus: ColumnStatus.Hidden,
    Type: Types.dropdown
  },
]

const practiceMapping: IColumns[] = [
  {
    field: 'name',
    label: 'Name',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Disabeld,
    Type: Types.string
  },
  {
    field: 'id',
    label: 'Id',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Disabeld,
    Type: Types.number
  },
  {
    label: 'Email Doctor Start Date',
    field: 'emailDoctorStartDate',
    SidebarEditStatus: ColumnStatus.Inabeld,
    SidebarNewStatus: ColumnStatus.Disabeld,
    Type: Types.dateTime
  },
  {
    label: 'Action',
    field: 'action',
    SidebarEditStatus: ColumnStatus.Hidden,
    SidebarNewStatus: ColumnStatus.Hidden,
    Type: Types.string
  },
]

const userMapping: IColumns[] = [
  {
    field: 'name',
    label: 'Name',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Hidden,
    Type: Types.string
  },
  {
    label:'First Name',
    field:'firstName',
    SidebarEditStatus:ColumnStatus.Hidden,
    SidebarNewStatus:ColumnStatus.Inabeld,
    Type:Types.string,
    mainStatus:ColumnStatus.Hidden
  },
  {
    label:'Last Name',
    field:'lastName',
    SidebarNewStatus:ColumnStatus.Inabeld,
    SidebarEditStatus:ColumnStatus.Hidden,
    mainStatus:ColumnStatus.Hidden,
    Type:Types.string
  },
  {
    field: 'emailAddress',
    label: 'Email Address',
    SidebarEditStatus: ColumnStatus.Disabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.number
  },
  {
    label: 'Role',
    field: 'role',
    SidebarEditStatus: ColumnStatus.Inabeld,
    SidebarNewStatus: ColumnStatus.Inabeld,
    Type: Types.enum,
    EnumValues: [
      new KeyValuePair({ key: 1, value: 'User' }),
      new KeyValuePair({ key: 2, value: 'Admin' })
    ]
  },
  {
    label: 'Action',
    field: 'action',
    SidebarEditStatus: ColumnStatus.Hidden,
    SidebarNewStatus: ColumnStatus.Hidden,
    Type: Types.string
  },
]

export {
  patiientMapping, practiceMapping, IColumns, userPracticeMapping, Types, ColumnStatus, userMapping
}
