export class UserPractice {
    id: number;

    userId: number;

    practiceId: number;

    userName: string;

    practiceName: string;

    eligibilityEmails: boolean=false;

    doctorEmails: boolean=false;

    doctorEmailsReply: boolean=false;

    role: number;

    isDefault: boolean=false;

    dateCreated: Date;

    public constructor(init?:Partial<UserPractice>) {
        Object.assign(this, init);
    }
}
