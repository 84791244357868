









import { Component, Vue } from "vue-property-decorator";
import dataTable from "../components/DataTable.vue";
import { tabelSettings, ITableSettings,userTableSettings} from "./Models/TableSettings";
import { UserPractice } from "./Models/TableEditClass";

@Component({
  components: {
    dataTable,
  },
})
export default class Users extends Vue {
  usersTable:ITableSettings=userTableSettings
}
