import {
  IColumns, patiientMapping, practiceMapping, userPracticeMapping,userMapping
} from './TableColumnsMapping'

interface ITableSettings{
    TableLabel:string,
    TableName:string,
    EndPoint:string,
    TableMapping:IColumns[],
    TableParams:any
}

const PatientsTableSettings:ITableSettings = {
  TableLabel: 'Patients',
  TableName: 'Patients',
  EndPoint: 'Patients',
  TableMapping: patiientMapping,
  TableParams: null,
}
const userPracticeTableSettings:ITableSettings = {
  TableName: 'UserPractice',
  TableLabel: 'Email Doctor Settings',
  EndPoint: 'UserPractice',
  TableMapping: userPracticeMapping,
  TableParams: null,
}

const practiceTableSettings:ITableSettings = {
  TableName: 'Practice',
  TableLabel: 'Practice',
  EndPoint: 'Practice',
  TableMapping: practiceMapping,
  TableParams: null,
}

const userTableSettings:ITableSettings = {
  TableName: 'User',
  TableLabel: 'User',
  EndPoint: 'User',
  TableMapping: userMapping,
  TableParams: null,
}
const tabelSettings:ITableSettings[] = [
  PatientsTableSettings,
  userPracticeTableSettings,
  practiceTableSettings
]
export { tabelSettings, userPracticeTableSettings,PatientsTableSettings,practiceTableSettings, ITableSettings,userTableSettings }
